<div class="slide" style="padding-top: 5%">
  <div class="divorce-page-bg "></div>
  <div class="sex-page ps-5" id="sex-page">
    <p class="display-2 text-uppercase right-pad fw-bold ps-5" style="font-size: 4.7vw">
      Давай
    </p>
    <div class="light ok-enum pe-3">
      <p class="ps-5 pe-5"> К сожалению, произошла ошибка.</p>
    </div>
    <button class="btn btn-lg nav-link text-uppercase ms-5" routerLink=""
            role="button">На главную</button>
  </div>
</div>
