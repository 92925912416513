import { Component, ElementRef, ViewChild, AfterViewInit, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-marquee',
    templateUrl: './marquee.component.html',
    styleUrls: ['./marquee.component.css'],
    standalone: true
})
export class MarqueeComponent implements AfterViewInit {
  @ViewChild('marquee_container') container!: ElementRef;
  @Input() gap?: string;
  @Input() speed?: string;

  ngAfterViewInit(): void {
    let container = this.container.nativeElement as HTMLElement;
    let wrapper = container.firstChild as HTMLElement;
    let content = wrapper.firstChild as HTMLElement;

    let big_width = container.offsetWidth;
    let small_width = content.offsetWidth;

    let num_clones = Math.ceil(big_width / small_width);

    content.style.paddingInlineEnd = this.gap || '0.5rem';

    for (let i = 0; i < num_clones; ++i) {
      let clone = content.cloneNode(true) as HTMLElement;
      clone.setAttribute('aria-hidden', 'true');
      wrapper.append(clone);
    }

    let speed = parseInt(this.speed || '50');
    let duration = small_width / speed;

    wrapper.animate([
      {transform: "translateX(0px)"},
      {transform: `translateX(-${small_width}px)`}
    ], {duration: duration * 1000, iterations: Infinity})
  }
}
