import { AfterViewInit, Component } from '@angular/core';

import { HomePageData } from 'app/network/cms.service';
import { CMSService } from 'app/network/cms.service';
import { Observable } from 'rxjs';
import { MarqueeComponent } from '../../marquee/marquee.component';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';
import { LetDirective } from '@ngrx/component';

@Component({
    selector: 'app-home-second-slide',
    templateUrl: './home-second-slide.component.html',
    styleUrls: ['./home-second-slide.component.scss'],
    standalone: true,
    imports: [LetDirective, NgFor, RouterLink, MarqueeComponent]
})
export class HomeSecondSlideComponent {
  data$: Observable<HomePageData>;

  constructor(homeData: CMSService) {
    this.data$ = homeData.homePageData();
  }
}
