import { Component } from '@angular/core';
import { SocialType } from 'app/social-media-share/social-media-share.component';
import { SocialMediaShareComponent } from '../../social-media-share/social-media-share.component';
import { SocialMediaChooseComponent } from '../../social-media-choose/social-media-choose.component';

@Component({
    selector: 'app-about-us-modal',
    templateUrl: './about-us-modal.component.html',
    styleUrls: ['./about-us-modal.component.css'],
    standalone: true,
    imports: [SocialMediaChooseComponent, SocialMediaShareComponent]
})
export class AboutUsModalComponent {
  public socialType: SocialType | null = null;
}

