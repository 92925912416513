import {Component} from '@angular/core';
import { HomeAboutSlideComponent } from '../home-slides/home-about-slide/home-about-slide.component';
import { HomeFourthSlideComponent } from '../home-slides/home-fourth-slide/home-fourth-slide.component';
import { HomeThirdSlideComponent } from '../home-slides/home-third-slide/home-third-slide.component';
import { HomeSecondSlideComponent } from '../home-slides/home-second-slide/home-second-slide.component';
import { HomeFirstSlideComponent } from '../home-slides/home-first-slide/home-first-slide.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [HomeFirstSlideComponent, HomeSecondSlideComponent, HomeThirdSlideComponent, HomeFourthSlideComponent, HomeAboutSlideComponent]
})
export class HomeComponent {
}

