import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ExtendBuyOptions} from "../models/BuyOptions";
import {environment} from 'environments/environment';
import { Observable, map } from 'rxjs';

export type FormErrors = {
  [field: string]: {message?: string, code?: string}[]
}

type APIResponseError = {success: false, errors?: FormErrors, message?: string};

export type BuyAPIResponse = APIResponseError |
 {success: true, redirect: string};

export type BuyResponse = {redirect: string};

export class FormError extends Error {
  public errors: FormErrors = {};
  constructor(response: APIResponseError) {
    super(response.message || "Произошла ошибка");
    if (response.errors) this.errors = response.errors;
    Object.setPrototypeOf(this, FormError.prototype);
  }
}

function handleBuyAPIResponse(api_resp: BuyAPIResponse): BuyResponse {
  if (api_resp.success) return api_resp;
  throw new FormError(api_resp);
}

@Injectable()
export class HttpService {
  constructor(private http: HttpClient) {
  }

  requestJson<T>(method: 'get' | 'post', url: string, body: any = undefined, options: Object = {}): Observable<T> {
    return this.http.request<T>(method,
      new URL(url, environment.backendURL).href,
      {body, ...options, responseType: 'json'});
  }

  requestText(method: 'get' | 'post', url: string, body: any, options: Object = {}): Observable<string> {
    return this.http.request(method,
      new URL(url, environment.backendURL).href,
      {body, ...options, responseType: 'text'});
  }

  buyRequest(formData: FormData): Observable<BuyResponse> {
    return this.requestJson<BuyAPIResponse>('post', 'cards/buy/', formData).pipe(map(handleBuyAPIResponse))
  }

  extendBuyRequest(buyOptions: ExtendBuyOptions): Observable<BuyResponse> {
    const formData = new FormData();
    formData.append('url', buyOptions.url)
    return this.requestJson<BuyAPIResponse>('post', 'cards/extend-buy/', formData).pipe(map(handleBuyAPIResponse));
  }

  openCard(url: string, openedCard: number) {
    const formData = new FormData();
    formData.append('url', url)
    formData.append('openedCard', openedCard.toString())
    return this.requestText('post', 'cards/open-card/', formData);
  }

  checkExtendOrder(orderId: number) {
    const formData = new FormData();
    formData.append('orderId', orderId.toString())
    return this.http.post(new URL('cards/check-extend-order/', environment.backendURL).href, formData, {responseType: 'json'});
  }
}
