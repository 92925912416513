<div class="background"></div>
<div class="mt-5" style="margin: 0 auto; text-align: center">
  <img src="/assets/да.png" alt="..." width="70px" />
</div>
<div
  class="ps-lg-5 ps-md-5 first-page"
  id="first-page"
  style="padding-bottom: 80px; padding-top: 40px"
>
  <div
    class="text-start fs-4 fw-bold text m-auto"
    style="width: 70%"
  >
    <p class="text-center fs-2 text-uppercase fw-bold" style="color: #cfd17d">
      Ваш платеж успешно обработан
    </p>
    <p class="text-uppercase fw-bold mb-5 mt-5">
      Номер заказа: {{ this.orderId }}
    </p>
    <ng-container *ngIf="status.message_sent; else message_not_sent">
      <p class="fw-light mt-5">
        На почту вам придет чек об оплате и ссылка на оплаченную колоду вопросов.
        После первого перехода на ссылку, она будет доступна {{status.access_duration}}, однако вы всегда
        сможете доплатить еще 499 рублей и получить доступ к вопросам на {{status.extend_duration}}.
      </p>
    </ng-container>
    <ng-template #message_not_sent>
      <p class="fw-light mt-5">
        К сожалению, нам не удалось отправить вам ссылку на оплаченную колоду вопросов.
        Ваша ссылка: <a href="{{status.link}}">{{status.link}}</a>.
        <strong>Перейдите по ней, когда будете готовы начать &mdash;</strong>
        после первого перехода на ссылку, она будет доступна {{status.access_duration}}, однако вы всегда
        сможете доплатить еще 499 рублей и получить доступ к вопросам на {{status.extend_duration}}.
      </p>
    </ng-template>
    <p class="fw-light text-uppercase mt-2">
      Мы подготовили для вас всплывающие подсказки, которые помогут разобраться с правилами.
      Желаем вам бережного разговора!
    </p>
  </div>
</div>
