import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { HttpService } from './http.service';
import {catchError, EMPTY, map, Observable, of, switchMap} from 'rxjs';
import { environment } from 'environments/environment';

export type APILinkDetails = {success: false} | {
  success: true,
  isActive: boolean,
  children: boolean,
  openedCards: string,
  linkExpired: Date,
}

export type LinkData = {
  children: boolean,
  openedCards: number[],
  linkExpired: Date | null,
}

export const resolveLink: ResolveFn<LinkData> = function(route, state) {
  if ((environment.skipAuth || route.parent!.url[0].path == 'free') && route.url[0].path == 'try') {
    return of({
      children: false,
      openedCards: [],
      linkExpired: null,
    })
  }
  let url = route.paramMap.get('url')!;
  const service = inject(HttpService);
  const router = inject(Router);
  const formData = new FormData();
  formData.append('url', url)
  return service.requestJson<APILinkDetails>('post', 'cards/check/', formData).pipe(
    switchMap((data) => {
      if (data.success) {
        if (data.isActive) {
          return of({
            children: data.children,
            openedCards: data.openedCards.split(",").map(x => parseInt(x)).filter(isFinite),
            linkExpired: new Date(data.linkExpired),
          })
        } else {
          router.navigate(['/link-expired', url.toString()]);
          return EMPTY;
        }
      } else {
        router.navigate(['/link-does-not-exist', url.toString()]);
        return EMPTY;
      }
    })
  );
}
