<div class="second-page container-fluid" id="second-page" *ngrxLet="data$ as data">
  <div class="first-part my-4">
    <div class="row align-items-end">
      <div class="col-sm-5 mb-3 mb-sm-4">
        <img width="250px" src="/assets/lets-main-logo-pirple.svg" class="float-lg-end float-md-end float-sm-start lets-image">
      </div>
      <div class="col-sm-7">
        <p class="text-uppercase fw-bold fs-3 ms-lg-5 ms-md-5 ms-sm-0 mt-sm-5 me-3 smaller-text">
          МОЖНО ИСПОЛЬЗОВАТЬ КАК <br/> ПЕРВЫЙ ШАГ ДЛЯ ВЫСТРАИВАНИЯ <br/> БЕРЕЖНОЙ КОММУНИКАЦИИ.
        </p>
      </div>
    </div>
    <div class="pt-5 d-none d-md-block">
      <img src="/assets/cards-second.svg" class="image">
    </div>
    <h3 class="col text-center text-uppercase fw-bold fs-3">
      ВЫ МОЖЕТЕ ПОГОВОРИТЬ О:
    </h3>
    <div class="mt-3 mx-3 mx-md-5 deck-button-container">
      <a class="deck-button red-enum text-center"
        *ngFor="let button of data.deck_buttons"
        [routerLink]="button.url">{{button.label}}</a>
    </div>
  </div>

  <app-marquee class="bg-white fw-bold mt-5 text-center fs-4">| {{data.deck_count}} колод и 500+ вопросов на самые сложные темы</app-marquee>
</div>
