import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {Observable, pluck} from "rxjs";
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-fail-payment-check',
    templateUrl: './fail-payment-check.component.html',
    styleUrls: ['./fail-payment-check.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class FailPaymentCheckComponent implements OnInit {
  orderId!: string;
  message!: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params['orderId'];
    this.message = this.route.snapshot.data['status']['message'];
  }
}
