import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-failed',
    templateUrl: './failed.component.html',
    styleUrls: ['./failed.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class FailedComponent {
}
