import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CmsPageComponent } from '../cms-page/cms-page.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface CmsContentPage {
  content: string;
}

@Component({
  selector: 'app-content-page',
  standalone: true,
  imports: [],
  templateUrl: './content-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPageComponent extends CmsPageComponent<CmsContentPage> {
  content: SafeHtml;

  private sanitizer = inject(DomSanitizer);

  constructor() {
    super();
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.data.content);
  }
}
