<div *ngrxLet="data$ as data" class="game-page">
  <div class="twopane pt-3 px-5" *ngrxLet="(data.payment ? buyFormStrings[data.payment.access_term] : undefined) as params">
    <div class="left-pane">
      <h1 class="h2">{{data.title}}?</h1>
      <img [src]="data.title_image"
        width="80%"
        class="deck-image"
        alt="Логотип колоды"
        role="presentation"
      />
    </div>
    <div class="right-pane" role="main">
      <h2 [innerText]="data.subheading"></h2>
      <ng-container *ngIf="params; else indev">
        <h3 class="h5 fw-normal smaller-text-2 mt-3 mb-2">Эти вопросы помогут вам:</h3>
        <div class="smaller-text-2 mt-0 game-page-description" [innerHtml]="data.description"></div>
        <h2 class="my-3">Как играть?</h2>
        <div class="smaller-text-2 game-page-description" [innerHtml]="data.how_to_play"></div>
        <a
          class="btn btn-primary btn-lg mt-3 break-on-phone"
          role="button"
          [routerLink]="params.button_link"
          [queryParams]="queryParams"
          [fragment]="params.button_fragment"
          [innerHtml]="params.button_text.replace('{ACCESS_DURATION}', data.payment!.access_duration)"
          ></a>
        <ng-template #buyModal *ngIf="data.payment?.access_term != 'Free'">
          <div class="modal-header">
            <h5 class="modal-title" id="buy-modal-title" [innerHtml]="params.modal_title"></h5>
            <button type="button" class="btn-close" (click)="navigateOutOfBuy()" aria-label="Close"></button>
          </div>
          <form #buyForm validate class="form-group" (keydown.enter)="buyForm.submit()"
            (submit)="$event.preventDefault(); buyRequest(buyForm)" tabindex="1">
            <input type="hidden" hidden readonly name="deck" [value]="data.slug" />
            <div class="modal-body">
              <div class="ps-4 pe-4">
                <label>Ваш e-mail:
                  <span #email_error hidden class="ms-3 alert-danger">
                    Некорректный адрес электронной почты!
                  </span>
                  <input class="form-control" type="email" name="email" required id="buy-email" ngbAutofocus
                    onblur="checkValidity()" (input)="email_error.hidden = true;" (invalid)="email_error.hidden = false;" />
                  <span class="mt-3" for="buy-email" [innerHtml]="params.email_hint"></span>
                </label>
              </div>
              <div class="form-check mt-4 ps-4 pe-4" *ngIf="data.children">
                <input class="form-check-input" type="checkbox" name="children" id="children" />
                <label class="form-check-label" for="children">
                  Поставьте галочку, <strong>если у вас есть дети,</strong> – тогда мы подберем для
                  вас специальные вопросы!
                </label>
              </div>
              <div class="row mt-2 ps-4 pe-4" *ngIf="data.payment?.access_term == 'Paid'">
                <label for="promocode" class="col-form-label col-auto">Промокод:</label>
                <div class="col">
                  <input class="form-control" name="promocode" id="promocode" [value]="queryParams['promo'] || ''">
                </div>
              </div>
              <div class="form-check mt-4 ps-4 pe-4">
                <input class="form-check-input" type="checkbox" id="public" required>
                <label class="form-check-label" for="public">
                  <a class="link-dark" href="/assets/documents/offer.pdf" target="_blank">Условия оферты</a> принимаю
                </label>
              </div>
              <div class="form-check mt-2 mb-2">
                <input class="form-check-input" type="checkbox" id="confidence" required>
                <label class="form-check-label" for="confidence">
                  Согласен с
                  <a class="link-dark" href="/assets/documents/privacy-policy.pdf" target="_blank">политикой
                    конфиденциальности</a>
                </label>
              </div>
              <div class="form-check mt-2 mb-2">
                <input class="form-check-input" type="checkbox" id="personal" required>
                <label class="form-check-label" for="personal">
                  Согласен с
                  <a class="link-dark" href="/assets/documents/processing-of-personal-data.pdf" target="_blank">политикой
                    обработки персональных данных</a>
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary" [innerHtml]="params.submit_text">
              </button>
            </div>
          </form>
          <div *ngIf="buyError" class="modal-body fs-2" [innerHtml]="buyError"></div>
        </ng-template>
      </ng-container>
      <ng-template #indev>
        <h3 class="h5 fw-normal smaller-text-2 mt-3 mb-0 description-heading">Тут скоро появятся вопросы, которые помогут вам:</h3>
        <div class="mt-3 w-75 smaller-text-2 game-page-description" [innerHtml]="data.description"></div>
        <p class="mt-3 w-75 smaller-text-2">
          Мы дорабатываем качественные и классные вопросы вместе с нашей
          командой профессиональных психологов. Чтобы не пропустить начало
          продаж, подписывайтесь на наш телеграм-канал.
        </p>
        <a class="btn btn-lg nav-link text-uppercase red-bg mt-3"
            href="https://t.me/igradavay"
            >Подписаться</a>
      </ng-template>
      <br />
    </div>
    <p *ngIf="params && params.disclaimer_text"
      class="w-100 text-primary text-center"
      [innerHtml]="params.disclaimer_text"
    >
    </p>
  </div>
</div>
