import {Component} from '@angular/core';

@Component({
    selector: 'app-home-first-slide',
    templateUrl: './home-first-slide.component.html',
    styleUrls: ['./home-first-slide.component.css'],
    standalone: true
})
export class HomeFirstSlideComponent {
}
