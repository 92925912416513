<div class="about-page mt-lg-3 px-1 pt-5 text-center text-white text-uppercase">
  <h3 class="light fw-bolder text-wrap">
    КАК МОЖНО РАССКАЗАТЬ ОБ ИГРЕ?
  </h3>

  <div class="text-block m-auto">
    <p class="mt-5"> ВЫ МОЖЕТЕ ПОДЕЛИТЬСЯ РАССКАЗОМ ОБ ИГРЕ «ДАВАЙ» ПРОСТО
      НАЖАВ НА ИКОНКУ ПОДХОДЯЩЕЙ ВАМ СОЦСЕТИ.</p>
    <p class="mt-4 d-none d-md-block"> МЫ ЗАРАНЕЕ ПОДГОТОВИЛИ ДЛЯ TELEGRAM - КРАСИВЫЙ ТЕКСТ,
      ОБЪЯСНЯЮЩИЙ, В ЧЕМ СУТЬ ИГРЫ; ДЛЯ ВКОНТАКТЕ - ПРИМЕРЫ КАРТОЧЕК С ВОПРОСАМИ; А ДЛЯ TWITTER - ОСТРОУМНУЮ ПОДПИСЬ К
      КЛИКАБЕЛЬНОЙ ССЫЛКЕ.</p>
    <div class="d-flex flex-row mt-3 justify-content-center">
      <div class="mx-md-3 mx-1 w-fit-content" *ngFor="let socialType of socialTypes">
        <label>
          <button [class]="socialType + ' social'"
            (click)="chosen.emit(socialType)"></button>
          <span class="visually-hidden">{{socialType}}</span>
        </label>
      </div>
    </div>


    <p class="mt-3">ЕСЛИ ВАШИМ ДРУЗЬЯМ ТОЧНО НУЖНА ЭТА ИГРА - ВЫ МОЖЕТЕ ПРОСТО
      ПРИСЛАТЬ ИМ ССЫЛКУ НА НАШ САЙТ. А ЕЩЕ МЫ ГОТОВИМ НАСТОЛЬНУЮ ВЕРСИЮ ИГРЫ. ПОДПИСЫВАЙТЕСЬ НА НАШ TELEGRAM, ЧТОБЫ НЕ
      ПРОПУСТИТЬ АНОНС:)</p>

    <div class="d-flex flex-row mt-1 justify-content-center">
      <div class="w-fit-content">
        <a href="https://t.me/igradavay" class="mt-2">
          <div class="telegram-publish social"></div>
        </a>
      </div>
    </div>
  </div>
</div>
