import { Component, Input } from '@angular/core';
import { HomePageData } from 'app/network/cms.service';
import { CMSService } from 'app/network/cms.service';


import dedent from 'dedent';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, combineLatestWith, map } from 'rxjs';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

function sharedText({deck_count}: HomePageData) {
  return {
    telegram: [
      dedent`— Психологическая игра “Давай”: тут нет победителей и проигравших.
        Это самостоятельный процесс психотерапии для пары или партнерского союза из нескольких человек.
        Возможность поговорить про ваши отношения честно и открыто. Шанс обсудить ваши заветные планы и мечты.
        Инструмент для планирования вашего переезда. А еще дополнительные колоды про осознанное родительство,
        развод и, конечно, про секс.`,
      dedent`— Я уже попробовал психологическую игру “Давай”, попробуй и ты!
        “Давай” — это возможность узнать себя и своего партнера лучше. Это одобренный профессиональными психологами
        инструмент, который выведет вас на честный и глубокий разговор о самом важном. А еще это милые детали,
        заветные мечты, страстные желания и самые смелые планы.`,
      dedent`— Психологическая игра “Давай” — инструмент для саморефлексии
        и первый шаг к выстраиванию бережной и искренней коммуникации с партнером.
        Все просто: ${deck_count} колод на разные темы и 500+ глубоких вопросов для размышления. С тебя
        только желание и свободное время.`,
      dedent`— Психологическая игра “Давай” – или как научиться разговаривать друг с другом о самом главном.
        Секс, отношения, дети или переезд – темы, на которые говорить, кажется, сложнее всего на свете.
        Мы сделали для вас ${deck_count} колод карт с десятками вопросов в каждой, чтобы помочь поговорить о самом главном так,
         чтобы все друг друга поняли.`,
      dedent`– Психологическая игра “Давай” – это как поход к терапевту, только
        никуда не надо идти и ни с кем не нужно разговаривать!
        Ладно. Поговорить придется – но только со своим партнером и только о самом главном.
        “Давай” – это ${deck_count} колод карт с вопросами об отношениях, семье, работе и сексе. Вместе со своим партнером вы
        сможете в комфортной обстановке проговорить то, что волнует вас больше всего, и понять друг друга, не выходя
        из собственной зоны комфорта.`,
    ],
    twitter: [
      dedent`“Судя по аватарке, ты педик”, “Айтишники — это новая интеллигенция” и другие фразы, которые не помогут выстроить здоровую коммуникацию с партнером. А вот вопросы из психологической игры “Давай” помогут`,
      dedent`Выбрать город разврата Тбилиси или остаться в Москве во внутренней эмиграции? Проверь себя и своего партнера на готовность переехать. Поговорите про эмиграцию, не упоминая минимальные 10к на релокейт и выжженное поле из путинистов`,
      dedent`Кажется, Твиттеру этого сейчас очень не хватает. Как понять, что вас обманывают в отношениях? Что для вас красный флаг? Где расставить свои границы? Узнай с помощью психологической игры “Давай”`,
      dedent`девочка: хочет драмы и киношного выяснения отношений

      девушка: хочет здоровую и бережную коммуникацию, отличный секс, общие планы и мечты, осознанное родительство или отказ от него.

      женщина:  заходит на`,
      dedent`— То есть если я не использую вопросы от игры “Давай”, мне сложнее наладить отношения, улучшить секс и построить бережную коммуникацию с партнером?
      — Здравствуйте. Да, такие условия. Надеемся на понимание.`,
      dedent`Как выстроить диалог с партнером, чтобы вместо твитов Евгения Ройзмана получилось пресловутое “словами через рот” ? `,
      dedent`Как разговаривать про секс без рабочих дырочек на парковке Ашана, легальных гимнасток из Барнаула и богатых бисексуалок-некроманток? Что выбрать моногамию или полиаморию?`,
    ],
    vk: [
      dedent`Психологическая игра Давай: учимся говорить друг с другом. ${deck_count} разных колод для интересных бесед с партнером`,
      dedent`Как наладить отношения с мужем, женой или детьми? Как вернуть чувства? Как улучшить секс? — Игра Давай!`,
      dedent`Как узнать человека лучше? Психологическая игра Давай — ваш первый шаг к выстраиванию здоровых отношений`,
      dedent`Научиться разговаривать друг с другом о самом главном. ${deck_count}+ колод психологических вопросов от игры Давай`,
      dedent`Наладить отношения или узнать себя лучше? Игра Давай – это как поход к терапевту, только никуда не надо идти`,
      dedent`Научиться слышать друг друга и починить ваши отношения. Игра Давай. Одобрено профессиональными психологами`,
      dedent`Основанная на психологии личности игра Давай: 500+ вопросов на важные темы для вас и вашего партнера`,
      dedent`Игра вместо психологической консультации? Легко! Сыграйте в “Давай” и познайте друг друга заново!`,
    ]
  };
}

export type SocialType = keyof ReturnType<typeof sharedText>;

interface ShareFormParams {
  url: string,
  url_param: string,
  text_param: string,
  texts: string[]
}

const shareParams: Record<SocialType, Omit<ShareFormParams, 'texts'>> = {
  telegram: {url: "https://t.me/share/url", url_param: 'url', text_param: 'text'},
  vk: {url: "https://vk.com/share.php", url_param: 'url', text_param: 'title'},
  twitter: {url: "https://twitter.com/intent/tweet", url_param: 'url', text_param: 'text'}
}

@Component({
    selector: 'app-social-media-share',
    templateUrl: './social-media-share.component.html',
    styleUrls: ['./social-media-share.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, AsyncPipe]
})
export class SocialMediaShareComponent {
  socialValue$ = new BehaviorSubject<SocialType|null>(null);

  @Input() set socialType(socialType: SocialType | null) {
    this.socialValue$.next(socialType);
  }
  get socialType() { return this.socialValue$.value; }

  constructor(homePageData: CMSService) {
    this.shareFormParams$ = this.socialValue$.pipe(
      combineLatestWith(homePageData.homePageData().pipe(map(sharedText))),
      map(([type, texts]) => type ? {...shareParams[type], texts: texts[type]} : null)
    )
  }

  site = environment.site

  socialTypes: SocialType[] = ["telegram", "vk", "twitter"];

  shareFormParams$!: Observable<ShareFormParams | null>;
}
