import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CmsFormComponent } from 'app/forms/cms-form/cms-form.component';

@Component({
    selector: 'app-home-fourth-slide',
    templateUrl: './home-fourth-slide.component.html',
    styleUrls: ['./home-fourth-slide.component.css'],
    standalone: true,
    imports: [RouterLink, CmsFormComponent]
})
export class HomeFourthSlideComponent {
}
