import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from "@angular/router";

@Component({
    selector: 'app-link-failure',
    templateUrl: './link-failure.component.html',
    styleUrls: ['./link-failure.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class LinkFailureComponent implements OnInit {

  data = '';
  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.data = this.route.snapshot.data.toString();
  }

}
