<div class="third-page container-fluid">
  <div class="first-part">
    <img class="a-b" src="/assets/a-b.svg">
    <div class="row pt-4 align-items-end">
      <div class="col-sm-5 mb-3 mb-sm-4">
        <img width="250px" src="/assets/lets-main-logo-yellow.svg" class="float-lg-end float-md-end float-sm-start lets-image">
      </div>
      <div class="col-sm-7">
        <img class="a-b-phone" src="/assets/a-b.svg">
        <p class="text-uppercase fw-bold fs-3 ms-lg-5 ms-md-5 ms-sm-0 mt-sm-5 me-3 smaller-text">
          МОЖНО РАССМАТРИВАТЬ <br/>КАК САМОСТОЯТЕЛЬНЫЙ <br/>ПРОЦЕСС ПСИХОТЕРАПИИ
        </p>
        <img class="b-c-phone" src="/assets/b-c.svg">
      </div>
    </div>
    <p class="light text-center fs-4 fw-bold pt-5 text-uppercase smaller-text-2">
      Если в процессе ответов на вопросы из наших интерактивных колод вы поймете, <br/>что в вашей с партнером коммуникации
      образовались провалы, <br/>которые самостоятельно преодолеть слишком больно или невозможно, <br/>обратитесь к семейному
      психотерапевту
    </p>
    <p class="light text-center fs-5 pt-5 tighter">
      ВЫБЕРИТЕ ПОДХОДЯЩЕГО СПЕЦИАЛИСТА
    </p>
    <div class="yellow-button text-center fs-3" routerLink="partners">
      <a>НАШИ ПАРТНЕРЫ</a>
    </div>
    <img class="b-c" src="/assets/b-c.svg">
  </div>

  <app-marquee class="bg-white fw-bold mt-5 text-center fs-4">| К СЕБЕ И К ДРУГИМ С ЗАБОТОЙ И НЕЖНОСТЬЮ</app-marquee>
</div>
