import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormError, HttpService } from '../network/http.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-extend-buy',
    templateUrl: './extend-buy.component.html',
    styleUrls: ['./extend-buy.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class ExtendBuyComponent {
  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService
  ) {}

  error = '';

  buy() {
    let url = this.route.snapshot.paramMap.get('url')!;
    this.httpService.extendBuyRequest({url}).subscribe({
      next: ({redirect}) => { window.location.href = redirect },
      error: (e) => {
        if (e instanceof FormError) {
          this.error = e.message;
        } else {
          this.error = `К сожалению, мы не смогли обработать ваш заказ.`
        }
      }
    })
  }
}
