import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, inject, Input, OnInit, signal, effect, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CmsPageComponent, PageData } from 'app/cms/cms-page/cms-page.component';
import { WagtailService } from 'angular-wagtail';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/network/http.service';
import { CommonModule } from '@angular/common';
import { environment } from 'environments/environment';

interface CmsFormPage {
  form: string;  // HTML form contents
  preamble: string;
  side_pane: string;
  footer: string;
}

interface FormPageContent {
  title: string;
  preamble?: SafeHtml;
  side_pane?: SafeHtml;
  footer: string;
}

@Component({
  selector: 'app-cms-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cms-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsFormComponent implements OnInit {
  @Input("path") pathOverride: string | null = null;
  @ViewChild("userform", {static: true}) userFormElement?: ElementRef;
  url!: string;
  content?: FormPageContent;
  formContents = signal<SafeHtml>('');
  error = signal<boolean>(false);

  private http = inject(HttpService);
  private sanitizer = inject(DomSanitizer);
  private wagtail = inject(WagtailService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  html(s: string, defined?: false): SafeHtml | undefined;
  html(s: string, defined: true): SafeHtml;

  html(s: string, defined?: boolean) {
    return (s || defined) ? this.sanitizer.bypassSecurityTrustHtml(s) : undefined;
  }


  ngOnInit() {
    if (this.pathOverride) {
      this.wagtail.getPageForUrl(this.pathOverride).subscribe((pageData) => {
        if (pageData) {
          this.formContents.set(this.html((pageData as PageData<CmsFormPage>).form, true));
          // Don't set preamble or side_pane for inline mode
        }
      })
      this.url = new URL(this.pathOverride, environment.backendURL).href;
    } else {
      const cmspage = this.route.snapshot.data['cmsData'] as PageData<CmsFormPage>;
      this.content = {
        title: cmspage.title,
        preamble: this.html(cmspage.preamble),
        side_pane: this.html(cmspage.side_pane),
        footer: cmspage.footer,
      }
      this.formContents.set(this.html(cmspage.form, true));
      this.url = new URL(this.router.url, environment.backendURL).href;
    }
    if (!this.url.endsWith('/')) {
      this.url = this.url + '/';
    }
  }

  onSubmit(form: HTMLFormElement, event: SubmitEvent) {
    event.preventDefault();
    this.error.set(false);
    this.http.requestText('post', form.action, new FormData(form)).subscribe({
      next: (result) => {
        this.formContents.set(this.html(result, true));
        setTimeout(() => this.scrollToError(), 0);
      },
      error: (err) => { this.error.set(true); },
    })
  }

  scrollToError(): void {
    let form = this.userFormElement?.nativeElement;
    if (!form) return;
    let first_error = form.querySelector(".errorlist");
    let relevant_input: HTMLInputElement | null = form.querySelector(".errorlist ~ input");
    first_error?.scrollIntoView();
    relevant_input?.focus();
    relevant_input?.select();
  }
}
