import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { PaymentStatus } from 'app/network/check-payment-status';
import { Observable, map } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-success-payment-check',
    templateUrl: './success-payment-check.component.html',
    styleUrls: ['./success-payment-check.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class SuccessPaymentCheckComponent implements OnInit {
  orderId!: string;
  status!: PaymentStatus;
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.orderId = this.route.snapshot.params['orderId'];
    this.status = this.route.snapshot.data['status'];
  }
}
