import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-link-does-not-exist',
    templateUrl: './link-does-not-exist.component.html',
    styleUrls: ['./link-does-not-exist.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class LinkDoesNotExistComponent {
}
