import { Signal, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { WagtailPageDetail } from 'angular-wagtail';
import { map } from 'rxjs';

export type PageData<T> = T & WagtailPageDetail;

export class CmsPageComponent<Data extends object> {
  data: PageData<Data>;

  constructor() {
    const route = inject(ActivatedRoute);
    this.data = route.snapshot.data['cmsData'] as PageData<Data>;
  }
}
