<div class="fourth-page container-fluid">
  <div class="first-part">
    <img class="fly-img" src="/assets/fly.svg">
    <div class="row pt-4 align-items-end">
      <div class="col-sm-5 mb-3 mb-sm-4">
        <img width="250px" src="/assets/lets-main-logo-red.svg"
             class="float-lg-end float-md-end float-sm-start lets-image">
      </div>
      <div class="col-sm-7">
        <p class="text-uppercase fw-bold fs-3 ms-lg-5 ms-md-5 ms-sm-0 mt-sm-5 me-3 smaller-text">
          УЖЕ ПОПРОБОВАЛИ НАШУ ИГРУ? <br/> РАССКАЖИТЕ НАМ, КАК ОНА <br/> ПОВЛИЯЛА НА ВАС И ВАШИ <br/>ОТНОШЕНИЯ.
        </p>
      </div>
    </div>

    <div class="row pt-4 d-none d-md-flex">
      <div class="col-5" style="margin-top: 80px;">
        <div style="padding-left: 13vw">
          <div class="arrow-bg">
            <p class="text-uppercase fw-bold fs-3 text-all-comment">
              ВСЕ ОТЗЫВЫ<br/> МОЖНО<br/> ПОЧИТАТЬ ТУТ
            </p>
          </div>
        </div>
        <p class="text-uppercase fw-bold fs-3 show-sm">
          ВСЕ ОТЗЫВЫ МОЖНО ПОЧИТАТЬ ТУТ:
        </p>
        <div class="btn red-button text-center w-75 fs-3 button-read" routerLink="/comments">
          <a>Узнать, как игра “Давай”<br/> помогла другим парам</a>
        </div>
      </div>
      <div class="col-7">
        <div class="w-75">
          <app-cms-form path="/story"></app-cms-form>
        </div>
      </div>
    </div>

    <div class="row d-block d-md-none">
      <div class="col-12">
        <a class="btn btn-primary d-block m-auto fs-3 rounded-pill" routerLink="/story">Оставьте отзыв</a>
      </div>
      <div class="btn red-button text-center w-75 fs-3 button-read smaller-btn" routerLink="comments">
        <a>читать все отзывы</a>
      </div>
    </div>
  </div>
</div>
