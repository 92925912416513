import {Component, HostListener, Injectable, OnInit} from '@angular/core';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import {MatMenuModule} from "@angular/material/menu";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import { RouterLink } from "@angular/router";

import { HomePageData } from 'app/network/cms.service';
import { MenuItem } from 'app/network/cms.service';
import { CMSService } from 'app/network/cms.service';
import { Observable, map } from 'rxjs';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LetDirective } from '@ngrx/component';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [LetDirective, MatToolbarModule, NgIf, MatButtonModule, MatMenuModule, NgFor, MatIconModule, RouterLink, MatDividerModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem]
})
export class HeaderComponent implements OnInit {
  menu_items$!: Observable<MenuItem[]>;
  upButtonIsVisible: boolean = false;
  opengameLeftDropdownMenu = false;
  openLeftDropdownMenu = false;
  openLeftMainDropdownMenu = false;
  openLeftmainGameDropdownMenu = false;

  constructor(public router: Router, home_page_data: CMSService) {
    this.menu_items$ = home_page_data.homePageData().pipe(
      map(hp => hp.main_menu)
    )
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.upButtonIsVisible = window.scrollY > 400;
  }

  ngOnInit(): void {
  }

}
