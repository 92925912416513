import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SocialType } from 'app/social-media-share/social-media-share.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-social-media-choose',
    templateUrl: './social-media-choose.component.html',
    styleUrls: ['./social-media-choose.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class SocialMediaChooseComponent {
  socialTypes: SocialType[] = ["telegram", "vk", "twitter"];

  @Output() chosen = new EventEmitter<SocialType | null>();
}
