import {Component, Input} from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.css'],
    standalone: true,
    imports: [DatePipe]
})
export class TimerComponent {
  @Input() linkExpired: Date = new Date();
  constructor() {
  }
}
