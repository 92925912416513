import { Component, OnInit } from '@angular/core';
import { GamePageData, GameCards, Card, CardCategory } from 'app/models/game-data';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpService } from 'app/network/http.service';
import { BaseMenuItem, CMSService } from 'app/network/cms.service';
import { LinkData } from 'app/network/check-link';
import { Observable, map, BehaviorSubject, combineLatestWith, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { AboutUsModalComponent } from '../modals/about-us-modal/about-us-modal.component';
import { TimerComponent } from '../timer/timer.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { LetDirective, PushPipe } from '@ngrx/component';

type PageAndCards = {page: GamePageData, cards: GameCards, linkData: LinkData};

interface GameData extends GamePageData {
  categoryList: CardCategory[];
}

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.css'],
    standalone: true,
    imports: [
      LetDirective,
      PushPipe,
      RouterLink,
      TimerComponent,
      NgIf,
      NgFor,
      AboutUsModalComponent
    ]
})
export class GameComponent implements OnInit {
  order_id: string | null = null;
  data$: Observable<GameData>;  // TODO should be a signal
  showTip = true;
  isActiveLink = false;
  allCardsAreShown = false;
  continueIsShown = false;
  continueSh1 = false;
  continueSh2 = false;
  linkExpired: Date | null = null;
  showForChildren = new BehaviorSubject<boolean>(false);
  openedCards = new Set<string>();
  selectedCard: Card | null = null;
  allCardsCache: Card[] = [];

  public sections: BaseMenuItem[] = [];
  public randomSection: BaseMenuItem | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    homeData: CMSService,
  ) {
    this.data$ = route.data.pipe(
      map(d => d as PageAndCards),
      tap((data) => {
        let {cards: {card_categories}, linkData, page} = data;
        this.allCardsCache = ([] as Card[]).concat.apply(
          [], card_categories.map(cc => cc.cards)
        );
        this.linkExpired = linkData.linkExpired;
        this.showForChildren.next(linkData.children);
        this.openedCards = new Set<string>(linkData.openedCards.map(x => this.allCardsCache[x]?.image))
      }),
      combineLatestWith(this.showForChildren),
      map(([data, showForChildren]) => ({
        ...data.page,
        categoryList: this.makeCategoryList(data.cards, showForChildren)
      })));
    route.paramMap.subscribe((pm) => {
      let url = pm.get("url");
      this.order_id = url === "try" ? null : url;
    })
    homeData.homePageData().subscribe((d) => this.sections = d.random_suggest);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.continueSh1 = true;
    }, 30 * 60 * 1000);

    setTimeout(() => {
      this.continueSh2 = true;
    }, 60 * 60 * 1000);

  }

  toggleShowForChildren() {
    this.showForChildren.next(!this.showForChildren.value);
  }

  makeCategoryList(data: GameCards, showForChildren: boolean) {
    let {card_categories, promo} = data;

    if (!promo) return card_categories;

    const OPTIMAL_CARDS_BETWEEN_PROMO = 14;

    let cardlist = [] as CardCategory[];
    let cards_so_far = 0;

    cardlist.push(promo);

    for (let category of card_categories) {
      if (showForChildren || !category.children) {
        let size = category.cards.length;
        if (OPTIMAL_CARDS_BETWEEN_PROMO - cards_so_far <
            cards_so_far + size - OPTIMAL_CARDS_BETWEEN_PROMO) {
          cardlist.push(promo);
          cards_so_far = 0;
        }
        cards_so_far += size;
        cardlist.push(category);
      }
    }
    if (cards_so_far * 2 > OPTIMAL_CARDS_BETWEEN_PROMO) {
      cardlist.push(promo);
    }
    return cardlist;
  }

  getUrl() {
    return environment.site + this.router.url;
  }

  open(card: Card) {
    this.selectedCard = card;
    this.openedCards.add(card.image);

    if (this.order_id) {
      this.httpService.openCard(this.order_id, this.allCardsCache.indexOf(card)).subscribe(()=>{});
    }
    this.updateShownCards();
  }

  buy() {
    if (this.order_id) {
      this.router.navigate(['/extend-buy', this.order_id]);
    }
  }

  updateShownCards() {
    this.allCardsAreShown = this.allCardsCache.length == this.openedCards.size;
    if (this.allCardsAreShown && this.sections.length > 0) {
      this.randomSection = this.sections[Math.floor(Math.random() * this.sections.length)];
    }
  }
}
