<div class="comments-page" id="comments-page">
  <p class="text-center fs-2 text-uppercase fw-bold">
    Продлить доступ к игре давай на месяц
  </p>
  <button type="button" class="btn btn-lg nav-link text-uppercase red-bg mt-5"
          style="margin-left: auto; margin-right: auto"
          data-bs-dismiss="modal" (click)="buy()">Купить
  </button>
  <p *ngIf="error" class="text-center fs-2 text-uppercase fw-bold">
    {{error}}
  </p>
  <p *ngIf="error" class="text-center fs-2 text-uppercase fw-bold">
    Пожалуйста, обратитесь в <a href="mailto:help@igradavay.ru">поддержку</a>.
  </p>
</div>
