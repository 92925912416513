import { Component, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet]
})
export class AppComponent {
  title = 'game';

  constructor() {
    const router = inject(Router);
    const document = inject(DOCUMENT);
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        if (val.url == '/') {
          document.body.classList.add('header-footer-dark');
        } else {
          document.body.classList.remove('header-footer-dark');
        }
      }
    });
  }
}
