import { Component } from '@angular/core';
import { MarqueeComponent } from '../../marquee/marquee.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-home-third-slide',
    templateUrl: './home-third-slide.component.html',
    styleUrls: ['./home-third-slide.component.css'],
    standalone: true,
    imports: [RouterLink, MarqueeComponent]
})
export class HomeThirdSlideComponent {
}
