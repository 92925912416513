import { Component, AfterViewInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, RouterLink, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Access, GamePageData } from 'app/models/game-data';
import { FormError, HttpService } from 'app/network/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { LetDirective } from '@ngrx/component';

@Component({
    selector: 'app-game-page',
    templateUrl: './game-page.component.html',
    styleUrls: ['./game-page.component.scss'],
    standalone: true,
    imports: [LetDirective, NgIf, RouterLink],
    encapsulation: ViewEncapsulation.None,
})
export class GamePageComponent implements AfterViewInit {
  @ViewChild("buyModal") buyModal?: TemplateRef<any>;
  data$: Observable<GamePageData>;
  queryParams!: Params;
  buyError: string | null = null;

  constructor( private route: ActivatedRoute, private router: Router, private httpService: HttpService,
               private modalService: NgbModal) {
    this.data$ = route.data.pipe(map(d => d['page'] as GamePageData));
    route.queryParams.subscribe((params) => this.queryParams = params);
  }

  ngAfterViewInit(): void {
    // связываем fragment == buy
    let ref: NgbModalRef | null = null;
    this.route.fragment.subscribe((f) => {
      if (f === 'buy' && !ref && this.buyModal) {
        ref = this.modalService.open(this.buyModal, {
          animation: true,
          ariaLabelledBy: 'buy-modal-title'
        });
        ref.dismissed.subscribe((_) => {
          this.navigateOutOfBuy();
          ref = null;
        })
      } else if (f !== 'buy' && ref) {
        ref.dismiss();
        ref = null;
      }
    });
  }

  navigateOutOfBuy() {
    this.router.navigate(this.route.snapshot.url, {queryParams: this.queryParams});
  }

  buyRequest(form: HTMLFormElement) {
    this.httpService.buyRequest(new FormData(form)).subscribe({
      next: ({redirect}) => { window.location.href = redirect; },
      error: (e) => {
        if (e instanceof FormError) {
          this.buyError = e.message;
        } else {
          this.buyError = `Ошибка: сервер недоступен`;
        }
      }
    })
  }

  buyFormStrings = {
    [Access.Paid]: {
      modal_title: `Введите данные для совершения покупки`,
      submit_text: `Купить`,
      email_hint: `На эту почту мы пришлем вам ссылку на доступ к этой прекрасной
                   колоде карт. Внимательно проверьте, правильно ли все
                   ввели, чтобы не потерять доступ к оплаченной колоде`,
      button_text: `Доступ к вопросам<br>
                    на {ACCESS_DURATION} — 499 рублей`,
      button_link: '.',
      button_fragment: 'buy',
      disclaimer_text: `Обратите внимание, что отсчёт времени доступа начинается с момента перехода по ссылке из почты`
    },
    [Access.Trial]: {
      modal_title: `Введите данные для получения доступа`,
      submit_text: `Отправить`,
      email_hint: `На эту почту мы продублируем ссылку на страницу игры, чтобы вы могли
                   вернуться к ней позже.`,
      button_text: `Доступ к вопросам<br>
                    на {ACCESS_DURATION}`,
      button_link: '.',
      button_fragment: 'buy',
      disclaimer_text: `Обратите внимание, что отсчёт времени доступа начинается с момента получения доступа`
    },
    [Access.Free]: {
      modal_title: undefined,
      submit_text: undefined,
      email_hint: undefined,
      button_text: `Попробовать бесплатно`,
      button_link: './try',
      button_fragment: undefined,
      disclaimer_text: undefined,
    }
  }
}
