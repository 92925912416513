import {inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ResolveFn, Router} from '@angular/router';
import {environment} from "../../environments/environment";
import {catchError, EMPTY, Observable, of, switchMap} from "rxjs";

export type PaymentStatus = {
  success: true,
  completed: boolean,
  status_code: string,
  message: string,
  link: string,
  message_sent: boolean,
  access_duration: string,
  extend_duration: string,
}

export class PaymentStatusResolver {
  constructor(
    private backendPath: string,
    private successSegment: string,
    private failSegment: string,
  ) { }

  checkOrder(http: HttpClient, orderId: string): Observable<PaymentStatus | {success: false}> {
    let url = new URL(this.backendPath, environment.backendURL);
    url.searchParams.append('orderId', orderId);
    return http.get<PaymentStatus>(
      url.href, {responseType: 'json'}).pipe(
        catchError((_e) => of({success: false} as {success: false}))
    );
  }

  resolve: ResolveFn<PaymentStatus> = (route, state) => {
    const router = inject(Router);
    const http = inject(HttpClient);
    return this.checkOrder(http, route.params['orderId']).pipe(
      switchMap((paystatus) =>{
        if (!paystatus.success) {
          router.navigate(['failed'], {skipLocationChange: true});
          return EMPTY;
        }
        let {completed} = paystatus;
        let target = completed ? this.successSegment : this.failSegment;
        if (route.url[0].toString() !== target) {
          router.navigate([target, route.params['orderId']]);
          return EMPTY;
        }
        return of(paystatus);
      })
    )
  }
}

