import gamePageData from 'data/game-page-data.stream.yaml';
import gameCardsData from 'data/game-cards.stream.yaml';
import { GamePageData, GameCards, CardCategory } from 'app/models/game-data';

export function gameCards(): {[slug: string]: GameCards} {
  let result: {[slug: string]: GameCards} = {};
  for (let cardsData of gameCardsData) {
    let cards = cardsData as GameCards;
    let prefix = `/assets/${cards.folder || cards.slug}-cards/`;

    function prefixCategory(category: CardCategory) {
      return {
        ...category,
        back_image: prefix + category.back_image,
        cards: category.cards.map((card) => ({
          ...card,
          image: prefix + card.image,
        }))
      }
    }

    result[cards.slug] = {
      ...cards,
      card_categories: cards.card_categories.map(prefixCategory),
      promo: cards.promo && prefixCategory(cards.promo),
    }
  }
  return result;
}
