export enum Access {
	Free = 'Free',
	InDev = 'InDev',
	Paid = 'Paid',
  Trial = 'Trial',
}

export type PaymentScenario = {
  access_term: 'Free' | 'Trial' | 'Paid',
  access_duration: string,
  extend_duration: string,
}

export type Card = {
	image: string,
	description?: string
}

export type CardCategory = {
	back_image: string,
	cards: Card[],
	children?: boolean,
}

export type GamePageData = {
	slug: string,
	title: string,
	title_image: string,
	subheading: string,
	description: string,
	how_to_play?: string,
	children?: boolean,
  additional_instructions?: string,
  payment?: PaymentScenario,
}

export type GameCards = {
	slug: string,
  folder?: string,
	card_categories: CardCategory[],
  promo?: CardCategory,
}
